import { useContext } from 'react'
import { loginSchema } from 'utils/validationSchema'
import { login } from 'api/authentication'
import { useTranslate } from 'utils/hooks'
import { isCapacitor } from 'utils'

import Page from 'components/page'
import { Formik, Form } from 'formik'
import Button from 'components/button'
import Link from 'components/link'
import Field from 'components/field'
import Checkbox from 'components/field/checkbox'

import GlobalContext from 'context'

import { SetSubmitting, LoginData } from 'types'

import { faAt, faLock } from '@fortawesome/free-solid-svg-icons'

export default function Login() {
    const { updateGlobalContext } = useContext(GlobalContext)
    const { t } = useTranslate()
    
    function handleLogin(values: LoginData, setSubmitting: SetSubmitting) {
        login(values)
        .then(contextData => updateGlobalContext(contextData))
        .catch(() => setSubmitting(false))
    }

    return(
        <Page seoTitle={t('Accedi')} className="min-h-screen flex sm:mt-0" noSpacing>
            <div className="flex-1 hidden sm:block bg-secondary">
                <div className="flex flex-col items-center justify-center h-full">
                    <img src="/images/logo-white.svg" alt="Easylivery" className="h-9 w-auto"/>
                    <img src="/images/home.svg" className="h-60 w-auto" alt="Dashboard"/>
                </div>
            </div>
            <div className="flex-1 flex flex-col justify-center">
                <div className="mx-auto w-full max-w-lg px-10 lg:px-14">
                    <div>
                        <img src="/images/logo.svg" alt="Easylivery" className="sm:hidden h-9 w-auto"/>
                        <h1 className="mt-6">{t('Accedi al tuo account')}</h1>
                        {!isCapacitor && <p className="max-w">{t('Non hai un account?')} <Link href="/signup" className="font-medium text-primary">{t('Registrati')}</Link></p>}
                    </div>
                    <Formik initialValues={{email: '', password: '', remember: false}} validationSchema={loginSchema} onSubmit={(values, {setSubmitting}) => handleLogin(values, setSubmitting)}>
                        {({isSubmitting}) => (
                            <Form className="mt-6 space-y-4">
                                <Field name="email" type="email" placeholder="mario.rossi@gmail.com" label={t('Email')} icon={faAt} className="block w-full"/>
                                <Field name="password" type="password" placeholder="••••••••" label={t('Password')} icon={faLock} className="block w-full"/>
                                <div className="flex items-center justify-between">
                                    <Checkbox name="remember" label={t('Ricordami')}/>
                                    <div className="leading-5">
                                        <Link href="/forgot-password" className="font-medium text-sm text-primary">{t('Password dimenticata?')}</Link>
                                    </div>
                                </div>
                                <Button type="submit" color="primary" loading={isSubmitting}>{t('Accedi')}</Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Page>
    )
}